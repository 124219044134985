import React from 'react'
import { Link } from 'gatsby'

import Root from '../layouts/Root'

const NotFoundPage = () => {
  const inlineStyles = {
    notFound: {
      maxWidth: 560,
      margin: '192px auto',
      padding: '0 16px',
      textAlign: 'center',
    },
  }

  return (
    <Root>
      <main style={inlineStyles.notFound}>
        <h2 className="t-heading-l">Deze pagina bestaat niet (404)</h2>

        <p>
          Het lijkt erop dat de pagina waarnaar u op zoek bent niet (meer)
          bestaat.
          <br />
          Probeer eens terug te keren naar de <Link to="/">homepagina</Link> en
          vanuit daar opnieuw te beginnen.
        </p>
      </main>
    </Root>
  )
}

export default NotFoundPage
